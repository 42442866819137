<template>
  <banner
    v-if="shouldShowBanner"
    color-scheme="primary"
    :banner-message="bannerMessage"
    href-link="https://ezyaa.de/mein-konto"
    :href-link-text="'Plan upgraden'"
    has-close-button
    @close="dismissUpdateBanner"
  />
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  components: { Banner },
  mixins: [adminMixin],
  data() {
    return {
      userDismissedBanner: false,
      currentUrl: '',
      bannerMessage: '',
      accountUsageData: null,
      showBanner: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    apiBaseUrl() {
      if (this.currentUrl.includes('localhost')) {
        return 'http://localhost:3001';
      } else if (this.currentUrl.includes('zrok')) {
        return 'http://localhost:3001';
      } else if (this.currentUrl.includes('ngrok')) {
        return 'http://localhost:3001';
      } else {
        return 'https://app.ezyaa.de:3001';
      }
    },
    shouldShowBanner() {
      return !this.userDismissedBanner && this.showBanner;
    },
  },
  methods: {
    async fetchAccountUsageData() {
      try {
        const response = await fetch(`${this.apiBaseUrl}/api/account-usage/${this.accountId}`);
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        const data = await response.json();
        this.accountUsageData = data;

        const messages = [];

        if (parseInt(data.usage.inboxes) >= parseInt(data.limits.inboxes)) {
          messages.push(`Limit für Inboxes (${data.usage.inboxes}/${data.limits.inboxes}) erreicht.`);
        }
        if (parseInt(data.usage.messages) >= parseInt(data.limits.messages)) {
          messages.push(`Limit für Nachrichten (${data.usage.messages}/${data.limits.messages}) erreicht.`);
        }
        if (parseInt(data.usage.agents) >= parseInt(data.limits.agents)) {
          messages.push(`Limit für Agenten (${data.usage.agents}/${data.limits.agents}) erreicht.`);
        }

        if (messages.length > 0) {
          this.bannerMessage = messages.join(' ') + ' Bitte upgraden Sie Ihren Plan.';
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Account-Nutzung:', error);
      }
    },
    dismissUpdateBanner() {
      this.userDismissedBanner = true;
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.currentUrl = window.location.href;
    }
    if (this.accountId) {
      this.fetchAccountUsageData();
    } 

  },
  watch: {
    accountId(newVal) {
      if (newVal) {
        this.fetchAccountUsageData();
      }
    },
  },
};
</script>
