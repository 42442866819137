<template>
  <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
    <div class="flex flex-wrap -mx-2 mb-4">
      <!-- Hidden div for Facebook SDK -->
      <div id="fb-root" style="display: none;"></div>
      <div class="w-full px-2 mb-4 ">
        <button
          type="button"
          class="w-full login-button bg-primary-600 text-white hover:bg-primary-700 transition-colors duration-300 py-2 px-4 rounded"
          @click="handleFacebookLogin"
        >
          FACEBOOK LOGIN
        </button>
      </div>
    </div>
    <div class="w-full mb-4">
    </div>

    <!-- Existing form fields -->
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.inboxName.$error }">
        {{ $t("INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL") }}
        <input
          v-model.trim="inboxName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="message">
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR") }}
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.phoneNumber.$error }">
        {{ $t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL") }}
        <input
          v-model.trim="phoneNumber"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="message">
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR") }}
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.phoneNumberId.$error }">
        <span>
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.LABEL") }}
        </span>
        <input
          v-model.trim="phoneNumberId"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.PLACEHOLDER')
          "
          @blur="$v.phoneNumberId.$touch"
        />
        <span v-if="$v.phoneNumberId.$error" class="message">
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.ERROR") }}
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.businessAccountId.$error }">
        <span>
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.LABEL") }}
        </span>
        <input
          v-model.trim="businessAccountId"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.PLACEHOLDER')
          "
          @blur="$v.businessAccountId.$touch"
        />
        <span v-if="$v.businessAccountId.$error" class="message">
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.ERROR") }}
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.apiKey.$error }">
        <span>
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.API_KEY.LABEL") }}
        </span>
        <input
          v-model.trim="apiKey"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.PLACEHOLDER')"
          @blur="$v.apiKey.$touch"
        />
        <span v-if="$v.apiKey.$error" class="message">
          {{ $t("INBOX_MGMT.ADD.WHATSAPP.API_KEY.ERROR") }}
        </span>
      </label>
    </div>

    <div class="w-full">
      <woot-submit-button
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import alertMixin from "shared/mixins/alertMixin";
import { required } from "vuelidate/lib/validators";
import router from "../../../../index";
import { isPhoneE164OrEmpty, isNumber } from "shared/helpers/Validators";
import globalConfig from "../../../../../../shared/store/globalConfig";

export default {
  mixins: [alertMixin],
  data() {
    return {
      inboxName: "",
      phoneNumber: "",
      apiKey: "",
      phoneNumberId: "",
      businessAccountId: "",
      sessionInfoResponse: "",
      sdkResponse: "",
      currentUrl: ""
    };
  },
  computed: {
    ...mapGetters({ uiFlags: "inboxes/getUIFlags" }),
    apiBaseUrl() {
      if (this.currentUrl.includes("localhost")) {
        return "http://localhost:3001";
      } else if (this.currentUrl.includes("zrok")) {
        // return 'https://iej2qxniya06.share.zrok.io';
        return "http://localhost:3001";
      } else if (this.currentUrl.includes("ngrok")) {
        return "http://localhost:3001";
      } else {
        return "https://app.ezyaa.de:3001";
      }
    },
    baseUrl() {
      if (this.currentUrl.includes("localhost")) {
        return "http://localhost:3000";
      } else if (this.currentUrl.includes("zrok")) {
        return "https://pz7z7vp997ev.share.zrok.io";
      } else if (this.currentUrl.includes("ngrok")) {
        return "http://localhost:3001";
      } else {
        return "https://app.ezyaa.de";
      }
    }
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
    apiKey: { required },
    phoneNumberId: { required, isNumber },
    businessAccountId: { required, isNumber }
  },
  mounted() {
    this.loadFacebookSDK();
    this.setupMessageListener();
    if (typeof window !== "undefined") {
      this.currentUrl = window.location.href;
    }
  },
  methods: {
    async fetchAccessToken() {
      console.log("XXXXX fetchAccessToken XXXXXX");
      const sdkResponse = JSON.parse(this.sdkResponse);
      const sessionInfoResponse = JSON.parse(this.sessionInfoResponse);
      const code = sdkResponse.authResponse.code;
      const phone_number_id = sessionInfoResponse.data.phone_number_id;
      const waba_id = sessionInfoResponse.data.waba_id;

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          code: code,
          phone_number_id: phone_number_id,
          waba_id: waba_id
        })
      };

      try {
        const responseFetch = await fetch(`${this.apiBaseUrl}/api/exchange-code`, requestOptions);
        const jsonResponse = await responseFetch.json();
        console.log(jsonResponse);
        this.apiKey = jsonResponse.access_token;
        this.phoneNumberId = jsonResponse.phone_number_id;
        this.businessAccountId = jsonResponse.waba_id;
        this.inboxName = jsonResponse.inboxName;
        this.phoneNumber = jsonResponse.phoneNumber;

        localStorage.setItem("wa", JSON.stringify(
          {
            inboxName: this.inboxName,
            phoneNumber: this.phoneNumber,
            apiKey: this.apiKey,
            phoneNumberId: this.phoneNumberId,
            businessAccountId: this.businessAccountId
          }
        ));
      } catch (err) {
        console.error(err);
      }
    },

    loadFacebookSDK() {
      window.fbAsyncInit = () => {
        console.log("Initializing Facebook SDK");
        FB.init({
          appId: "1574879426572404",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v20.0"
        });
        console.log("Facebook SDK initialized");

        // Check login status after initialization
        FB.getLoginStatus((response) => {
          console.log("Initial login status:", response);
          this.sdkResponse = JSON.stringify(response, null, 2);
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, "script", "facebook-jssdk"));
    },

    handleFacebookLogin() {
      FB.login((response) => {
        console.log("FB.login response:", response);
        this.sdkResponse = JSON.stringify(response, null, 2);

        if (response.authResponse) {
          console.log("Login successful, fetching WhatsApp Business Account info");
          this.fetchAccessToken();
        } else {
          console.log("Login failed or was cancelled by the user");
          this.showAlert(this.$t("INBOX_MGMT.ADD.WHATSAPP.LOGIN_FAILED"));
        }
      }, {
        config_id: "828871019355650",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "2"
        }
      });
    },

    setupMessageListener() {
      window.addEventListener("message", (event) => {
        if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
          return;
        }
        try {
          const data = JSON.parse(event.data);
          console.warn("Data Step at ", data);

          if (data.type === "WA_EMBEDDED_SIGNUP") {
            if (data.event === "FINISH") {
              const { phone_number_id, waba_id } = data.data;
              console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
            } else if (data.event === "CANCEL") {
              const { current_step } = data.data;
              console.warn("Cancel at ", current_step);
            } else if (data.event === "ERROR") {
              const { error_message } = data.data;
              console.error("error ", error_message);
            }
          }
          this.sessionInfoResponse = JSON.stringify(data, null, 2);
        } catch {
          console.log("Non JSON Responses", event.data);
        }
      });
    },

    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          "inboxes/createChannel",
          {
            name: this.inboxName,
            channel: {
              type: "whatsapp",
              phone_number: this.phoneNumber,
              provider: "whatsapp_cloud",
              provider_config: {
                api_key: this.apiKey,
                phone_number_id: this.phoneNumberId,
                business_account_id: this.businessAccountId
              }
            }
          }
        );

        router.replace({
          name: "settings_inboxes_add_agents",
          params: {
            page: "new",
            inbox_id: whatsappChannel.id
          }
        });
      } catch (error) {
        this.showAlert(
          error.message || this.$t("INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE")
        );
      }
    }
  }
};
</script>

<style scoped>
</style>
